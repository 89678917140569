.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.MuiBackdrop-root{
  position: absolute !important;
}

.chat-body{
  position: relative !important; 
}

.chat-scroll-avtive{
  position: relative !important;
  opacity: 0 !important;
}

 .emptyDataChat{display: none!important;}

.table .form-control {
    font-size: 14px;
    min-height: 46px;
}


 .react-form-wizard{padding-left:10%;padding-right:10%;}
.react-form-wizard {
  /*padding-bottom: 60px!important;*/
}

.react-form-wizard .wizard-card-footer{padding:5px 5px!important;}

.table td, .table th{
  vertical-align: middle;
 /* CSS for removing padding from .react-form-wizard on mobile devices */
}
.input-group-append {
    height: 46px!important;
}
@media (max-width: 768px) {
  .react-form-wizard {
    padding: unset;
  }
  .react-form-wizard .wizard-tab-content{width:unset;}
}

/*.wizard-icon-container {background: blue}*/

/*.stepTitle .active{color:blue!important;}*/



.react-form-wizard .active .wizard-icon-circle .wizard-icon-container{background: blue!important;}
.react-form-wizard .wizard-icon-circle.checked{border-color: blue!important;}


/*.react-form-wizard .active .wizard-icon-circle.checked .wizard-icon-container .ti-user {
    color: blue!important;
}

.react-form-wizard + .active .wizard-icon-circle.checked .wizard-icon-container .ti-user {
    color: white!important;
}
*/

.react-form-wizard li:not(.active) .wizard-icon-circle.checked .ti-user {
    color: blue!important; /* Color for non-active steps */
}

.react-form-wizard li:not(.active) .wizard-icon-circle.checked .ti-settings {
    color: blue!important; /* Color for non-active steps */
}

.react-form-wizard li:not(.active) .wizard-icon-circle.checked .ti-check {
    color: blue!important; /* Color for non-active steps */
}
.react-form-wizard .wizard-card-footer .wizard-footer-left{background: blue!important;border:blue!important;}

.react-form-wizard .wizard-card-footer .wizard-footer-right{background: blue!important;border:blue!important;}


.react-form-wizard .wizard-icon-circle.checked + .stepTitle  {
    color: blue!important;
}

.react-form-wizard .active .stepTitle.active{color: blue!important;}

.react-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{background:blue!important;color:blue!important;}

/* CustomAccordion.css */
/* Example of custom styles */
.custom-accordion .accordion-item {
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px; /* Add some border radius */
}

.custom-accordion .accordion-header {
  background-color: #002f6c;
    color: black;
    padding: 2px;
    margin: 0px;
    border: 1px solid #002f6c;
    position: relative;
}

/* HorizontalTable.css */
.horizontal-table {
 
  display: flex;
    flex-direction: row;
    width: 50%;

}


.horizontal-table1 {
  display: flex;
  flex-direction: row;
}


.table-header {
 display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
}

.table-header1 {
  display: flex;
  flex-direction: column;
  width: 65%;
    text-align: justify;

}

.table-content1 {
  display: flex;
  flex-direction: column;
  width: 35%;
    text-align: justify;

}

.table-header-item {
  flex: 1;
  padding: 1.2rem;
  border-bottom: 1px solid #ddd;
}

.table-content {
  display: flex;
  width:100%;
  
}

.table-row {
  display: flex;
  flex-direction: column;
  width:100%;
}

.table-row1 {
  display: flex;
  flex-direction: column;
  width:100%;
}

.table-cell {
  flex: 1;
  padding: 1.2rem;
  border-bottom: 1px solid #ddd;
}


.custom-accordion .accordion-header .accordion-button {
    background-color: unset;
    color: white;
    width: 100%;
    border: unset;
    font-size:24px; 
}

.custom-accordion .accordion-body {
  background-color: lightgrey; /* Change the background color of the body */
  padding: 10px; /* Add some padding */
}

.custom-accordion .accordion-header .accordion-button::after {
    /* content: "V"; */
    /* font-size: 10px; */
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
 
    vertical-align: 0.255em;
    content: "+";


    right: 0px;
    position: absolute;
    right: 30px;
    text-align: right;

/*    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;*/
}

.tooltip-container {
  position: relative;
}

.tooltip {
  position: relative;
/*  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);*/
  display: contents;
}

.tooltip:hover .tooltip-text {
  display: block;
}

.question-mark {
  cursor: pointer;
  color: blue;
}

.tooltip-text {
  display: none;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}



.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: #40e0d0;
}
.option-input:checked::before {
  width: 30px;
  height: 30px;
  display:flex;
  content: '\f00c';
  font-size: 16px;
  font-weight:bold;
  position: absolute;
  align-items:center;
  justify-content:center;
  font-family:'Font Awesome 5 Free';
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.react-form-wizard .wizard-header {
    padding: 0px!important;
 
}

.wizard-progress-with-circle{display: none;}
.form-wizard-steps{display: none!important;}


#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

}

#customers td, #customers th {
  border: 1px solid #ddd;
  
}

#customers tr:nth-child(even){background-color: #f2f2f2;}
#customers tr:nth-child(odd){background-color: white;}
#customers tr:hover {background-color: #ddd;}

#customers th {
 
  text-align: center;
    background-color: #f8ea55;
    color: #002f6c;
    position: sticky;
    top: 0;

}

#customers td {
 

  color: black;
}

.wizard-btn{display: none;}


/* Centered loader */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Ensure loader is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  background-color: #fff; /* Loader background color */
  color: #000; /* Loader text color */
  /*padding: 20px;*/
      padding: 10px 20px 10px 20px;
  border-radius: 5px;
  width:125px;
}


.spinner-square {
    display: flex;
    flex-direction: row;
    width: 90px;
    height: 120px;
}

.spinner-square > .square {
    width: 17px;
    height: 80px;
    margin: auto auto;
    border-radius: 4px;
}

.square-1 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}

.square-3 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@keyframes square-anim {
    0% {
        height: 80px;
        background-color: rgb(111, 163, 240);
    }
    20% {
        height: 80px;
    }
    40% {
        height: 120px;
        background-color: rgb(111, 200, 240);
    }
    80% {
        height: 80px;
    }
    100% {
        height: 80px;
        background-color: rgb(111, 163, 240);
    }
}
  .divider {
            text-align: center;
            margin: 20px 0;
        }
        .divider hr {
            border: none;
            height: 2px;
            background-color: #ccc;
            margin: 0;
        }