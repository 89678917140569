body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
.fc-list-event{color:unset!important}

.avatar-green::before {
    background-color: green;
}
.avatar-green::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: '';
    border: 2px solid #fff;
}
.demopart{display: none}

._1ceqH ._2fG9h {
    padding: 0px!important;
    margin-top: -7px!important;
    margin-left: 5px!important;
    margin-right: 0px;
}



.chat-compose{display: none!important;}
.MuiListItem-gutters .avatar{margin-right:5px;}
 ._1ceqH ._3nQu5{
    background-color: #e0e0e0!important;
    color: #333!important;
    padding: 10px 20px!important;
    border-radius: 5px;
    border: none!important;
    cursor: pointer!important;
    outline: none!important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)!important;
    transition: background-color 0.3s ease!important;
}

._1ceqH ._2ACrw{border-color:black;}

.selectedchatmember{background-color: #f5f5f6;}

.recommend_select,.recommend_deselect{color: green}

 .disabled-link {
    pointer-events: none; /* Disable click events */
    opacity: 0.6; /* Make it look faded or less prominent */
    text-decoration: none; /* Remove underline */
    
    cursor: not-allowed; /* Change cursor to indicate not allowed */
  }
  .emptyDataChat{display: none;}